.verticalFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.horizontalFlex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.centeredddd {
    width: 100%;
    margin: 100px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.solutionPageDiv {
    height: 300px;
    width: 300px;
    margin-left: 10px;
    padding: 0px;
    border-style: solid;
    border-color: rgb(0, 123, 255);
    border-width: 1px;
    border-radius: 10px;
    overflow: hidden;
    overflow-x: hidden;
    background-color: var(--defaultBackgroundColor);
}

.solutionContainer {
    overflow-y: auto;
    height: 85%;
}

.solutionContainer::-webkit-scrollbar {
    width: 12px;
}

.solutionContainer::-webkit-scrollbar-track {
    border-radius: 10px;
}

.solutionContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.solutionDiv {
    height: 20%;
    width: 100%;
    text-align: left;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.solutionDiv:hover {
    background-color: rgb(60, 190, 43);
}

.tabHeader {
    height: 15%;
    display: flex;
    flex-direction: row;
    background-color: #f6f4e2;
}

.tab {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.passive {
    background-color: #b1bfd8;
    background-image: linear-gradient(315deg, #b1bfd8 0%, #6782b4 74%);
    color: #ffffff;
}

.borderRightBottom {
    border-bottom-right-radius: 10px;
}

.borderLeftBottom {
    border-bottom-left-radius: 10px;
}

body::-webkit-scrollbar {
    width: 1em;
}
 
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.numberTextarea {
    outline: none;
    resize: none;
    width: 25px;
    height: 25px;
    font-size: 17px;
    text-align: center;
    color: transparent;
    text-shadow: 0 0 0 #000000;
    margin: 5px;
    overflow: hidden;
    background-color: var(--defaultBackgroundColor);
    line-height: initial;
    font-weight: 500;
}

.commonFont {
    font-size: 20px;
    text-align: center;
}

#boardTable{
    order: 1;
    margin-bottom: 5px;
}

#inputPage{
    order: 2;
    width: 170px;
    margin-bottom: 5px;
}

#solutionPage{
    order: 3;
}

.constantSizeButton {
    width: 90%;
    font-size: 15px;
    background-color: var(--defaultBackgroundColor);
    font-weight: 500;
    color: black;
    margin-bottom: 5px;
}

.halfConstantSizeButton {
    width: 48%;
    font-size: 15px;
    background-color: var(--defaultBackgroundColor);
    font-weight: 500;
    color: black;
    margin-bottom: 5px;
    text-align: center;
    padding: 0.375rem 0 !important;
}

.quickSaveButton {
    width: 100px;
    font-size: 15px;
    background-color: var(--defaultBackgroundColor);
    font-weight: 500;
    color: black;
}

.quickDeleteButton {
    width: 100px;
    font-size: 15px;
    background-color: var(--defaultBackgroundColor);
    font-weight: 500;
    color: red;
}

.quickSaveSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
}

.quickSaveHeight {
    height: 35px
}

.quickSaveInput {
    background-color: var(--defaultBackgroundColor);
    border-radius: .25rem;
    border-color: rgb(0, 123, 255);
    border-width: 1px;
    border-style: solid;
    outline: none;
}


.blockTextarea, .blockTextareaSolution {
    outline: none;
    resize: none;
    width: 23px;
    height: 23px;
    font-size: 20px;
    text-align: center;
    overflow: hidden !important;
    box-sizing: initial;
    border-radius: 3px !important;
    border-width: 0; 
    line-height: 1;
    background-color: var(--blockBackgroundColor);
    font-weight: bold;
    padding: 2px;
}

.blockTextarea--moveApplied {
    background-color: var(--appliedMoveBackgroundColor);
    box-shadow: inset 0 -3px var(--appliedMoveShadowColor);
    -webkit-appearance: none;
    color: var(--appliedMoveTextColor);
}

.jokerDot {
    position: absolute;
    background-color: red;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    top: 2px;
    left: 2px;
}

.blockTextareaSolution {
    color: red;
}

.blockTextarea.clicked {
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 0.2rem rgb(0, 123, 255);
}

.blockTextarea::selection{
    background:none;
}

.blockTextarea::-moz-selection{
    background:none;
}

.blockTextareaSolution::selection{
    background:none;
}

.blockTextareaSolution::-moz-selection{
    background:none;
}

.joker{
    background-color: rgb(235, 229, 52);
}

.kelime2, .kelime3, .harf2, .harf3, .tripleStar{
    background-attachment: inherit;
    background-repeat: no-repeat;
    -moz-background-size: contain; /* Firefox 3.6 */ 
    background-size: contain;
    background-position: center;
}

.kelime2{
    background-color: hsl(107deg 45% 73%);
    background-image: url("../images/Kelime2_white.png");
}

.kelime3{
    background-color: hsl(25deg 18% 65%);
    background-image: url("../images/Kelime3_white.png");
}

.harf2{
    background-color: hsl(208deg 53% 77%);
    background-image: url("../images/Harf2_white.png");
}

.harf3{
    background-color: hsl(325deg 57% 83%);
    background-image: url("../images/Harf3_white.png");
}

.tripleStar{
    background-image: url("../images/star.png");
    background-color: hsl(40deg 84% 75%);
}

tr td{
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1px !important; 
}

:root {
    --defaultBackgroundColor: #f6f4e2;
    --specialBlueColor: #302857;
    --blockBackgroundColor: hsl(0deg 0% 89%);
    --appliedMoveTextColor: hsl(21deg 36% 38%);
    --appliedMoveBackgroundColor: hsl(48deg 89% 79%);
    --appliedMoveShadowColor: hsl(64deg 49% 57%);
}

.centeredDiv {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.charsTextarea {
    outline: none;
    resize: none;
    height: 37px;
    font-size: 18px;
    text-align: center;
    overflow: hidden !important;
    line-height: initial;
    border-radius: .25rem;
    width: 90%;
    background-color: var(--defaultBackgroundColor);
    margin-bottom: 5px;
    border-color: rgb(0, 123, 255);
}

table {
    border: 0px;
    border-collapse: initial;
    border-spacing: 3px;
    table-layout: fixed;
    background-color: hsl(0deg 0% 70%);
}
    
td { 
    padding: 0px; 
    border-width: 0px; 
    margin: 0px;
}

.buttonContainer{
    width: 90%;
    justify-content: space-between;
    display: flex;
}

#infoBoxWrapper{
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
}

#infoBoxWrapper button{
    width: 10%;
    font-size: 15px;
    padding: 0;
    margin-bottom: 5px;
}

#infoBoxContainer{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    visibility: hidden;
    background-color: rgba(128,128,128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

#infoBox{
    border-radius: 0.3rem;
    border-style: solid;
    border-color: rgb(0, 123, 255);
    background-color: var(--defaultBackgroundColor);
    width: 50%;
    overflow: auto;
    max-height: 100%;
}

@media only screen and (max-width: 1000px) {
    .horizontalFlex {
        flex-wrap: wrap;
    }

    .constantSizeButton {
        font-size: 7px;
    }

    .halfConstantSizeButton {
        font-size: 7px;
    }
   
    #inputPage{
        width: 120px;
    }
    
    .charsTextarea {
        height: 20px;
        font-size: 11px;
    }

    table {
        border-spacing: 2px;
    }

    .commonFont {
        font-size: 11px;
    }

    .numberTextarea {
        width: 16px;
        height: 16px;
        font-size: 10px;        
    }

    .tabHeader {
        font-size: 10px;    
    }

    .solutionPageDiv {
        height: 200px;
        width: 150px;        
    }

    .solutionDiv {
        font-size: 10px;
    }

    .blockTextarea, .blockTextareaSolution {
        width: 17px;
        height: 17px;
        font-size: 16px;
        border-radius: 2px !important;
    }

    .blockTextarea--moveApplied {
        box-shadow: inset 0 -2px var(--appliedMoveShadowColor);
    }

    .jokerDot {
        height: 4px;
        width: 4px;
        top: 1px;
        left: 1px;
    }

    #infoBoxWrapper button{
        font-size: 7px;
    }

    #infoBox{
        width: 70%;
        font-size: 8px;
    }
}

.loadingContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: gray;
    opacity: 0.6;
}

.fullPage {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
}

.containerWidth {
    width: 100%;
}

.fixedFullPage {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
    background-color: rgba(128,128,128, 0.6);
}

.centeredParent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.areYouSureContainer, .showRemainingCharsView {
    background-color: #302857;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rowFlex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.areYouSureTitleContainer {
    color: white;
    padding: 10px 0px;
    margin: 12px 12px 0px 12px;
}

.loadingCircle{
    border-radius: 50%;
    width: 60px;
    height: 60px;
    border-width: 5px;
    border-color: var(--defaultBackgroundColor);
    border-style: solid;
    border-bottom-width: 5px;
    border-bottom-color: blue;
    border-bottom-style: solid;
    animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
    from {transform:rotate(0deg);}
    to {transform: rotate(360deg);}
}

.header {
  display: flex;
  flex-direction: row;
  background-color: #302857;
  height: 50px;
  align-items: center;
  justify-content: space-between;
}

.linkStyle, .headerTextStyle{
    text-decoration: none;
    color: white;
}

.activeLinkStyle{
    text-decoration: none;
    color: #ffff00;
}

.linkStyle:hover{
    text-decoration: none;
    color: #ffff00;
}

.RegisterBox, .LoginBox {
    display: flex;
    flex-direction: column;
    width: 600px;
    max-width: 60%;
    margin-top: 30px;
    padding: 20px 10px;
    border-radius: 0.5rem;
}

.RegisterBox > * {
    display: block;
    margin: 10px 0;
}

.LoginBox > * {
    display: block;
    margin: 10px 0;
}

.RegisterPageContainer, .LoginPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.RegisterLabel, .LoginLabel {
    text-align: center;
    font-size: 25px;
    color: #302857;
}

a {
    cursor: pointer;
}

.DropDownMenuContainer {
    position: absolute;
    top: 50px;
    right: 0px;
    width: 300px;
    min-height: 100px;
    max-width: 100%;
    background-color: #302857;
    border-color: rgb(255, 251, 0);
    border-style: solid;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DropDownItem, .DropDownTextBox, .AreYouSureOption {
    padding: 10px 0px;
    width: calc(100% - 24px);
    text-align: center;
    transition: background-color 0.5s ease;
    color: white;
    margin: 12px;
    border-radius: 5px;
}

.DropDownTextBox {
    color: black;
}

.DropDownItem:hover {
    background-color: #5d6a9b;
}

.AreYouSureOption:hover {
    background-color: #5d6a9b;
}

.leftRightPadding {
    padding: 0px 10px;
}

.leftRightMargin {
    margin: 0px 10px;
}

.remainingCharsContainer{
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--defaultBackgroundColor);
}

.remainingCharsGrid {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(8, 1fr);
    grid-row-gap: 0px;
    grid-column-gap: 0px;
    padding-bottom: 10px;
}

.singleCharRemainingContainer {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.singleChar {
    margin-left: 5px;
    background-color: yellow;
    width: 2em;
    border-radius: 2px;
    box-shadow: 0 3px orange;
    color: #302857;
    font-weight: 700;
}

.charsUsedUp {
    background-color: rgba(128,128,128,0.5);
    box-shadow: 0 3px grey;
}

.singleCharCount {
    margin-right: 5px;
    padding: 5px;
    color: #302857;
}


@media only screen and (max-width: 1000px) {
    .leftRightPadding {
        padding: 0px 5px;
    }
}

#tabContainer {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

#tabDeneme{
    padding-inline-start: 0;
    margin-block-end: 0;
    height: 35px;
}

#tabDeneme > li {
    display: inline-block;
    border: solid;
    border-color: var(--specialBlueColor);
    background-color: var(--defaultBackgroundColor);
    border-width: 2px;
    border-radius: 5px;
    padding-left: 1em;
    padding-right: 1em;
    min-height: 2em;

    border-top-width: 0;
    border-left-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    height: 100%;
}

#tabDeneme > li:first-child {
    border-left-width: 2px;
}

#tabDeneme > .selected{
    background-color: yellow;
}

#tabDeneme > li > div {
    max-width: 8em;
    min-width: 3em;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}